import React, { useEffect, useState, Fragment } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import Cookie from 'js-cookie';
import axios from 'axios';

import Input from './Input';
import File from './File';
import Textarea from './Textarea';

import { handleForm } from '../../functions/utils';

const Form = ({ id, type }) => {
    const [form, setForm] = useState(null);
    const [startData, setStartData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [complete, setComplete] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: { type: 'text', value: value } });
    };

    const fileSelectedHandler = (event) => {
        const { name, files } = event.target;
        setFormData({ ...formData, [name]: { type: 'file', value: files } });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const { message } = await handleForm('/app_handler.php', id, formData, type);
            if (message) {
                setComplete(message);
                setLoading(false);
                setForm(form);
                setFormData(startData);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleStartAgain = (e) => {
        e.preventDefault();
        setComplete(null);
    }

    useEffect(() => {
        if (form) {
            let initialData = {};
    
            form.sections.map((section) => {
                section.fields.map((field) => {
                    initialData = {...initialData, [field?.name]: { type: field?.type || field?.component, value: field?.defaultValue || '' }};
                });
            });
    
            setFormData(initialData);
            setStartData(initialData);
        }
    }, [form]);

    useEffect(() => {
        const fetchForm = async () => {
            try {
                const { data } = await axios.get('app_handler.php', { headers: { 'Wg-Method': type, 'Wg-Key': Cookie.get('accessToken'), 'Form-Id': id }});

                if (data) {
                    setForm(data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchForm();

    }, [id]);

    return (
        <Fragment>
            {!complete && form && (
                <form className="form" onSubmit={handleSubmit} enctype="multipart/form-data">
                    <div className="form__instructions">{form?.before_text}</div>
                    {form?.sections && form?.sections.map((section) => (
                        <Fragment>
                            {section?.label && (<div className="form__title">{section?.label}</div>)}
                            <div>
                                {section?.fields && section.fields.map((field) => {
                                    let renderedField;
                                
                                    if (field?.component === 'image' || field?.component === 'image_multiple') {
                                        renderedField = <File field={field} fileSelectedHandler={fileSelectedHandler} />
                                    } else if (field?.component === 'textarea') {
                                        renderedField = <Textarea field={field} handleChange={handleChange} />
                                    } else {
                                        renderedField = <Input field={field} handleChange={handleChange} />
                                    }
                                
                                    return renderedField;
                                })}
                            </div>
                        </Fragment>
                    ))}
                    <div className="form__buttons">
                        <button aria-label="Submit" disabled={!formData ? true : false} className="form__button form__button--inline" type="submit">Submit <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                    </div>
                </form>
            )}
            {complete && (
                <div>
                    <div className="text-center" style={{ fontSize: '1.25rem' }}>{complete}</div>
                    <div className="text-center" style={{ marginTop: '1rem' }}><button className="form__button" onClick={handleStartAgain}>Start again</button></div>
                </div>
            )}
        </Fragment>
    )
}

export default Form;
