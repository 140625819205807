import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as Card } from '../../assets/card.svg';
import './BottomNav.css';

const BottomNav = () => {
    const appSelector = useSelector((state) => state.app);
    const { navigation } = appSelector;
    const [padding, setPadding] = useState('0');

    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    
    // Checks if should display install popup notification:
    const handleIos = () => {
        if (isIos() && isInStandaloneMode()) {
            setPadding('2rem');
        }
    };

    useEffect(() => {
        handleIos();
    }, []);

    return (
        <div className="bottomNav" style={{ paddingBottom: padding }}>
            {navigation && navigation.map((item) => {
                return <NavLink className="bottomNav__link" activeClassName="active" to={item.path} exact>{item.icon && (<div dangerouslySetInnerHTML={{__html: item.icon}} />)}<div>{item.label}</div></NavLink>
            })}
            <NavLink className="bottomNav__link" activeClassName="active" to="/card"><Card style={{ width: '33px', height: '33px' }} />Card</NavLink>
        </div>
    )
}

export default BottomNav;
